import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
//import process7 from '../../assets/images/process/process7.png'
import small1 from '../../assets/images/process/process-small1.png'
import small4 from '../../assets/images/process/process-small4.png'
import small5 from '../../assets/images/process/process-small5.png'
import img3 from '../../assets/images/banner-img3.png'

const ContentThree = () => {
    return (
        <section className="process-area ptb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="banner" /> 
                            Les avantages de notre solution
                    </span>
                </div>
                <div className="row align-items-center m-0">
                    <div className="col-lg-6 col-md-12 p-0">
                        <div className="process-image">
                            <img src={img3} alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12 p-0">
                        <div className="process-content">
                            <div className="row">
                                <div className="col-lg-12 col-md-6">
                                    <div className="single-box-item">
                                        <div className="d-flex align-items-center">
                                            <div className="image">
                                                <img src={small1} alt="banner" />
                                            </div>
                                            <h3>Traitez rapidement la réclamation</h3>
                                            <div className="number"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-6">
                                    <div className="single-box-item">
                                        <div className="d-flex align-items-center">
                                            <div className="image">
                                                <img src={small1} alt="banner" />
                                            </div>
                                            <h3>Améliorez la satisfaction client</h3>
                                            <div className="number"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-6">
                                    <div className="single-box-item">
                                        <div className="d-flex align-items-center">
                                            <div className="image">
                                                <img src={small1} alt="banner" />
                                            </div>
                                            <h3>Fidélisez votre clientèle</h3>
                                            <div className="number"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-6">
                                    <div className="single-box-item">
                                        <div className="d-flex align-items-center">
                                            <div className="image">
                                                <img src={small4} alt="banner" />
                                            </div>
                                            <h3>Valorisez votre image de marque</h3>
                                            <div className="number"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-6">
                                    <div className="single-box-item">
                                        <div className="d-flex align-items-center">
                                            <div className="image">
                                                <img src={small5} alt="banner" />
                                            </div>
                                            <h3>Optimisez votre démarche d’amélioration continue</h3>
                                            <div className="number"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContentThree