import React from 'react'

const CartesAvantages = () => {
    return (
        <div className="upcoming-bootcamps-area ptb-70">
            <div className="container">
                <div className="row" id="relext">
                    <div className="col-lg-2 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i class='bx bxs-user-check color-relext' ></i>
                            </div>
                            <h3>
                                Ergonomique
                            </h3>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i class='bx bxs-lock color-relext' ></i>
                            </div>
                            <h3>
                                Sécurisé
                            </h3>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i class='bx bxs-devices color-relext'></i>
                            </div>
                            <h3>
                                Responsive
                            </h3>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i class='bx bx-world color-relext'></i>
                            </div>
                            <h3>
                                Multilingue
                            </h3>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i class='bx bxs-map-pin color-relext'></i>
                            </div>
                            <h3>
                                <span>100%</span>&nbsp;Français
                            </h3>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="bx bxs-arrow-from-left color-relext"></i>
                            </div>
                            <h3>
                                Traçabilité
                            </h3>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        
    )
}

export default CartesAvantages