import React from 'react'
import aboutImg3 from '../../assets/images/about/about-img3.png'

const ContentTwo = () => {
    return (
        <div className="upcoming-bootcamps-area ptb-70 bg-relext">
            <div className="container">
                <div className="upcoming-bootcamps-content">
                    <h3 className="h2-top">Enquête de satisfaction client, salon et évènements publics</h3>
                </div>

                <div className="overview-box">

                     <div className="overview-image">
                        <div className="image">
                            <img src={aboutImg3} alt="about" />
                        </div>
                    </div>

                    <div className="overview-content">
                        <div className="content">
                            <p>Elle mesure la façon dont les produits et services que vous fournissez répondent ou non aux attentes de votre clientèle.<br></br>
                            <span className='bold'>Les feedbacks</span> vous permettent de :</p>
                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Détecter une insatisfaction rapidement pour pouvoir mettre en place des actions d’amélioration</h4>
                            </div>

                            <div className="features-text bottom">
                                <h4><i className="flaticon-tick"></i> Recueillir les besoins de nouveautés ou d’amélioration</h4>
                            </div>

                            <p className="h2-top"><span className='bold'>Créez des questionnaires de satisfaction personnalisés</span> simples (oui/non) ou plus complexes avec des systèmes de notations et des espaces de commentaires.<br></br>
                            Les <span className='bold'>résultats sont automatiquement analysés</span>, et disponibles sous formes de listing et statistiques dynamiques.</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ContentTwo;