import React from 'react'
import tiers from '../../assets/images/tiers.png'

const ContentFour = () => {
    return (
        <div className="upcoming-bootcamps-area ptb-70 bg-relext">
            <div className="container bottom">
                <div className="upcoming-bootcamps-content">
                    <h2 className="bottom">Solution digitale d’évaluation des tiers et conformité</h2>
                    <p>La gestion des données fournisseurs devient de plus en plus complexe, et <span className='bold'>l’automatisation de vos processus</span> permet de gagner en efficacité.<br></br>
                    Outre les documents classiques (contrat, factures) la <span className='bold'>loi Sapin 2</span>, oblige les entreprises à <span className='bold'>évaluer les tiers</span> avec lesquelles elles veulent travailler.<br></br>
                    Ces informations concernent les certificats d’assurance, de conformité et de performance pour évaluer les risques financiers, économiques, environnementaux et réputationnels de vos tiers.</p>
                    <p><span className='bold color-compliance'>Wispeek</span> permet de <span className='bold'>collecter et centraliser les données sur un seul outil</span>.<br></br>
                    Travaillez dans un environnement sécurisé, où vos données sont chiffrées et accessibles selon des autorisations d’accès à différents acteurs internes et externes de l’entreprise.</p>
                </div>

                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content">
                            <h3>Procédures d’évaluation de la qualité des relations fournisseurs et intermédiaires :</h3>
                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Plateforme de dépôt de signalement confidentiel ou anonyme</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Questionnaire externe à adresser au tiers</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Fiche interne d’identité du tiers</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Classer les documents obligatoires et de certification reçus dans une bibliothèque</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Cartographie des risques</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Notification de mise à jour des documents</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Archivage du dossier (jusqu’à 5 ans après la cessation de relation)</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Tableau de bord de suivi général</h4>
                            </div>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            <img src={tiers} alt="about" />
                        </div>
                    </div>

                </div>
            </div>

            <div className="container pt-70">
                <div className="upcoming-bootcamps-content">
                    <h4 className="center">Nous simplifions la gestion de vos données pour que vous puissiez vous consacrer à maintenir une relation de confiance avec tous vos tiers !</h4>
                </div>
            </div>
        </div>

        
    )
}

export default ContentFour