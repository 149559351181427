import React from 'react'
import { Link } from 'gatsby'
//import img12 from '../../assets/images/about/about-img12.png'
import shape1 from '../../assets/images/about/about-shape1.png'
//import shape2 from '../../assets/images/our-mission/our-mission-shape2.png'
import starIcon from '../../assets/images/star-icon.png'
import process7 from '../../assets/images/process/process7.png'

const ModRelext = () => {
    return (
        <div className="about-area ptb-70 bg-relext">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-img">
                            <img src={process7} alt="banner" />
                            <div className="shape">
                                <img src={shape1} alt="banner" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" /> 
                                    Wispeek Relation Externe
                                </span>
                                <h2>Centralisez les données de vos tiers</h2>
                                <p>Aujourd’hui les organisations sont confrontées à un enjeu majeur qui est la gestion des <span className='bold'>Big data</span> ou <span className='bold'>« méga » données</span> de leurs tiers. Les informations sont devenues si volumineuses, diverses et rapidement transmises que seule la digitalisation permet de les traiter de manière efficace au quotidien.<br></br>
                                Clients, fournisseurs, partenaires, etc. les sources de données sont cruciales aussi bien pour <span className='bold'>garantir la satisfaction client, la qualité de vos relations externes que pour assurer la conformité de votre organisation</span>.</p>
                                <p>Collecter, traiter, mettre à jour, archiver, <span className='bold'>centralisez vos données dans un espace sécurisé</span> pour gagner en efficacité et surtout pour accéder facilement à vos informations.</p>
                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    Demandez une démo <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModRelext