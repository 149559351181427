import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import PageBanner from '../components/Common/PageBanner'
import UnProjet from "../components/Common/UnProjet"
import ModRelext from "../components/RelationExterne/ModRelext"
import Content from "../components/RelationExterne/Content"
import ContentTwo from "../components/RelationExterne/ContentTwo"
import ContentFour from "../components/RelationExterne/ContentFour"
import ContentThree from "../components/RelationExterne/ContentThree"
import CartesAvantages from "../components/RelationExterne/CartesAvantages"
import { Helmet } from "react-helmet"

const RelationExterne = () => {
  return (
    <Layout>
      <Helmet>
        <title>Wispeek by Seekreet | Solution de gestion des données tiers</title>
        <meta name="description" content="Collectez et traitez efficacement les informations provenant de vos tiers : réclamations clients, enquêtes de satisfaction, évaluations des tiers, fiches fournisseurs, etc." />
      </Helmet>
      <Navbar />
      <PageBanner
          pageTitle="Wispeek - Relation Externe" 
          homePageText="Accueil" 
          homePageUrl="/"
          parentActive="Wispeek" 
          parentPageUrl="/wispeek"
          activePageText="Relation Externe" 
      />
      <ModRelext />
      <Content />
      <ContentTwo />
      <ContentThree />
      <ContentFour />
      <CartesAvantages />
      <UnProjet />
      <Footer />
    </Layout>
  )
}

export default RelationExterne