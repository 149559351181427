import React from 'react'
import aboutImg1 from '../../assets/images/about/about-img1.png'

const Content = () => {
    return (
        <div className="upcoming-bootcamps-area ptb-70 bg-fafafb">
            <div className="container">
                <div className="upcoming-bootcamps-content">
                    <h2 className='pb-70'>Traitez et exploitez les données de vos clients</h2>
                    <h3 className="h2-top">Les réclamations clients</h3>
                </div>
                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content">
                            <p>La réclamation est un <span className='bold'>indicateur qualité essentiel</span> car il indique les sources d’amélioration du fonctionnement de l’entreprise. Notre système de remontées d’information permet de faciliter et coordonner les remontées et leur traitement à l’instar du traitement des non-conformités.</p>
                            <p><span className='bold color-compliance'>Wispeek</span> se <span className='bold'>conforme aux exigences des réglementations</span> sur le sujet (normes ISO 9001, ISO 10002) et permet de <span className='bold'>simplifier votre service de SAV</span> en envoyant directement l’information au référent concerné.</p>
                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Collecter les données via un formulaire de réclamation</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Rechercher les causes</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Mettre en place le plan d’action correctif</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Suivre vos indicateurs de performance</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Éditer et archiver le rapport</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Tableau de bord de suivi général</h4>
                            </div>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            <img src={aboutImg1} alt="about" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Content;